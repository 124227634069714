
import { defineComponent } from "vue";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "Card4",
  components: {},
  props: {
    cardClasses: {type: String, default: ""},

    avatar: {type: String, default: ""},

    online: {type: Boolean, default: false},

    initials: {type: String, default: ""},

    color: {type: String, default: ""},

    name: {type: String, default: ""},

    position: {type: String, default: ""},

    avgEarnings: {type: String, default: ""},

    totalSales: {type: String, default: ""},
  },
  setup() {
    const openDrawer = () => {
      DrawerComponent?.getInstance("kt_drawer_chat")?.toggle();
    };

    return {
      openDrawer,
    };
  },
});
